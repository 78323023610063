import "./site.webmanifest";
import "./fonts/nunito.css";
import "./scss/main.scss";

import "materialize-css/js/cash";
import "materialize-css/js/global";
import "materialize-css/js/anime.min";

import "materialize-css/js/sidenav"
import "materialize-css/js/buttons"
import "materialize-css/js/parallax"
import "materialize-css/js/dropdown"
import "materialize-css/js/collapsible"
import "materialize-css/js/carousel"
import "materialize-css/js/modal"
import "materialize-css/js/forms"
import "materialize-css/js/waves"

import ComparisonSlider from 'comparison-slider';
import {LuminousGallery} from 'luminous-lightbox';

require.context('./images', true, /\.(png|ico|jpe?g)$/);
require.context('./pdfs', true, /\.(pdf)$/);

const jsonForm = require('json-form-submit');

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

document.addEventListener('DOMContentLoaded', function () {
    var csrftoken = getCookie('csrftoken');

    var sidenav = M.Sidenav.init(document.querySelector('.sidenav'), {});

    window.closeSidenav = function () {
        if (sidenav)
            sidenav.close()
    };

    M.Parallax.init(document.querySelectorAll('.parallax'), {});
    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
        coverTrigger: false
    });
    M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
    M.Modal.init(document.querySelectorAll('.modal'), {
        onOpenEnd: function () {
            if (this.id === 'modal-kp')
                fbq('track', 'SubmitApplication');

            if (this.id === 'modal-callme')
                fbq('track', 'Lead', {
                    'content_category': 'modal',
                    'content_name': 'form-callme'
                });

            if (this.id === 'modal-cost-example')
                fbq('track', 'ViewContent', {
                    'content_category': 'modal',
                    'content_name': 'modal-cost-example',
                    'content_ids': ['Пример сметы']
                });

            if (this.id === 'modal-service-detail')
                fbq('track', 'ViewContent', {
                    'content_category': 'modal',
                    'content_name': 'modal-service-detail',
                    'content_ids': ['Состав услуги']
                });
        }
    });
    M.updateTextFields();

    // document.getElementsByTagName('nav')[0].offsetHeight

    const indexCarousel = M.Carousel.init(document.querySelectorAll('.carousel-index'), {
        fullWidth: true,
        indicators: true
    });
    if (indexCarousel.length)
        setInterval(() => {
            indexCarousel[0].next()
        }, 3500);

    new LuminousGallery(document.querySelectorAll("a.lightbox-item"), {
        arrowNavigation: true,
    });
    const designExampleGallery = new LuminousGallery(document.querySelectorAll("a.design-example"), {
        arrowNavigation: true,
    });

    var designExampleOpener = document.getElementById('design-example-opener');

    if (designExampleOpener)
        designExampleOpener.addEventListener('click', ($el) => {
            if (designExampleGallery.luminousInstances.length)
                designExampleGallery.luminousInstances[0].open();
            fbq('track', 'ViewContent', {
                'content_category': 'modal',
                'content_name': `design-example-opener-${$el.dataset.contentName}`,
                'content_ids': ['Состав дизайн проекта'],
            });
        }, false);

    var sliderInfo = document.getElementById('before-after-info');

    document.querySelectorAll('.carousel-comparison').forEach(function ($el) {
        const instance = M.Carousel.init($el, {
            fullWidth: true,
            indicators: true,
            onCycleTo: function ($current) {
                var compSlider = $current.querySelector('.ComparisonSlider');
                sliderInfo.querySelector('h5').textContent = compSlider.dataset.title || '';
                sliderInfo.querySelector('a').href = compSlider.dataset.href || '';
                sliderInfo.classList.toggle('ng-hide', !compSlider.dataset.title);
            }
        });

        $el.removeEventListener('mousemove', instance._handleCarouselDragBound);
        $el.removeEventListener('touchmove', instance._handleCarouselDragBound);
        //
        $el.querySelector('.carousel-next').addEventListener('click', function (event) {
            instance.next()
        }, false);
        $el.querySelector('.carousel-prev').addEventListener('click', function (event) {
            instance.prev()
        }, false)
    });

    document.querySelectorAll('.ComparisonSlider').forEach(function ($el) {
        new ComparisonSlider($el)
    });

    var formProcessing = function (id, url) {
        jsonForm({
            form_id: id,
            pre_post: function (formObj) {
                var xhr = new XMLHttpRequest();
                xhr.open("POST", url, true);
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
                xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        var modal = document.getElementById(id).querySelector('.modal');
                        modal.innerHTML = xhr.response;
                        M.updateTextFields();
                        if (xhr.status >= 400) {
                            document.getElementById(id).querySelector('.modal-content').scrollIntoView()
                        } else {
                            fbq('track', 'CompleteRegistration', {
                                content_name: id
                            });
                        }
                    }
                };
                var data = JSON.stringify(formObj);
                xhr.send(data)
            },
        })
    };

    var api_actions = {
        'form-callme': '/api/callme/',
        'form-kp': '/api/kp/',
    };
    for (var id in api_actions) {
        formProcessing(id, api_actions[id])
    }

    // facebook pixel events

    document.querySelectorAll('.fb-track-contact').forEach(function ($el) {
        $el.addEventListener('click', function () {
            fbq('track', 'Contact');
        })
    });
    document.querySelectorAll('.fb-track-view-content').forEach(function ($el) {
        $el.addEventListener('click', function () {
            let options = {
                content_category: $el.dataset.contentCategory,
                content_name: $el.dataset.contentName,
            };

            if ($el.dataset.contentIds)
                options['content_ids'] = $el.dataset.contentIds;

            fbq('track', 'ViewContent', options);
        })
    });

});